import React from 'react';
import { Switch } from 'react-router-dom';

/* Containers */
import PublicRoute from '../../../routes/containers/routepublic';

/* Pages - Iframes */
import IFrameSchedule from '../../../iframes/scheduler/page';
import IFrameReschedule from '../../../iframes/rescheduler/page';
import IFrameProfile from '../../../iframes/profile/page';
import IFrameWillowProfile from '../../../iframes/willowprofile/page';
import IFrameShop from '../../../iframes/shop/page';
import IFrameListings from '../../../iframes/listings/page';
import IFrameWebinars from '../../../iframes/webinars/page';
import IFrameWebinarTrainingVideo from "../../../iframes/webinars/trainingVideoPage";
import IFrameCheckout from '../../../iframes/checkout/page';
import IFrameSMS from '../../../iframes/smsbytoken/page';
import IframeRatings from '../../../iframes/ratings/page';
// import IframeCheckoutWillowPackages from '../../../iframes/checkoutwillow/containers/packageroom';
// import IframeCheckoutWillowReceipt from '../../../iframes/checkoutwillow/containers/receiptroom';
import IframeError from "../../../iframes/error/page";
import IframeExpertCreateUser from "../../../iframes/signupexpert/page";
import IframeWMCCreateUser from "../../../iframes/signupwmcuser/page";
import IframeMatching from '../../../iframes/matching/page';
import IFrameResetPassword from '../../../iframes/resetpassword/page';
import IFrameEmployerLogin from '../../../iframes/employerlogin/page';
import IFrameLogin from '../../../iframes/login/page';
import IFrameSignup from '../../../iframes/signup/page';
import IFramePrograms from '../../../iframes/programs/page';
import IFrameSignupMatch from '../../../iframes/signupmatch/page';
import IFrameAdvisorSignup from '../../../iframes/signupadvisor/page';
import IFrameAdvisorSignupPay from '../../../iframes/signupadvisorpay/page';
import IFrameSignupAdvisorSuccess from "../../../iframes/signupadvisor/success";
import IFrameAdvisorProfile from "../../../iframes/advisorprofile/page"
import IFrameAdvisorProfileV2 from "../../../iframes/advisorprofilev2/page"
import IFrameAdvisorProfileV2Scheduled from "../../../iframes/advisorprofilev2scheduled/page"
import IFrameAdvisorDirectory from '../../../iframes/advisordirectory/page';
import IFrameAdvisorDirectoryRedirect from '../../../iframes/advisordirectoryredirect/page';
import IFrameBadActorAdvisorDirectory from '../../../iframes/advisordirectorybadactor/page';
import IFrameAdvisorRequest from '../../../iframes/advisorrequestv2/page';
import IFrameCheckoutStripe from '../../../iframes/checkoutstripe/page';

export const iframeRoutes = [
    '/public/schedule/:handle',
    '/public/schedule/:handle/:eventId',
    '/public/schedule/:handle/:eventId/*',
    '/public/reschedule/:token',
    '/public/products/:handle',
    '/public/products/:handle/:listingId',
    '/public/products/:handle/:listingId/*',
    '/public/webinars/:handle',
    '/public/webinars/:handle/:webinarId',
    '/public/webinars/:handle/:webinarId/*',
    '/public/webinar-training/free/:handle',
    '/public/profile/:handle',
    '/public/profile/:handle/*',
    '/public/willow-profile/:handle',
    '/public/willow-profile/:handle/*',
    '/public/shop/:handle',
    '/public/shop/:handle/products',
    '/public/shop/:handle/products/*',
    '/public/shop/:handle/testimonials',
    '/public/shop/:handle/testimonials/*',
    '/public/shop/:handle/faqs',
    '/public/shop/:handle/faqs/*',
    '/public/shop/:handle/portfolio',
    '/public/shop/:handle/portfolio/*',
    '/public/shop/:handle/about',
    '/public/shop/:handle/about/*',
    '/public/shop/:handle/webinars',
    '/public/shop/:handle/webinars/*',
    '/public/checkout/:planId',
    '/public/programs',
    '/public/programs/:programId',
    '/public/programs/:programId/*',
    '/message-room/:roomId',
    '/rating/:roomId',
    '/client-rating/:roomId',
    // '/package/:roomId',
    // '/thank-you/:roomId',
    '/error',
    '/private/expert/create-user',
    '/private/wmc/create-user',
    '/private/coach-matching',
    '/password-reset',
    '/employee-register',
    '/login',
    '/signup',
    '/get-started',
    '/get-matched',
    '/get-scheduled',
    // '/advisor-signup',
    '/advisor-signup-no-cert',
    '/advisor-verify',
    '/public/advisor-profile/:handle',
    '/public/advisor-profile/:handle/*',
    '/public/advisor-profile-v2/:handle',
    '/public/advisor-profile-v2/:handle/*',
    '/public/advisor-match-scheduled-call',
    '/public/advisor-match-scheduled-call/*',
    '/public/advisor-directory',
    '/public/advisor-directory/*',
    '/public/advisor-directory-redirect',
    '/public/advisor-directory-redirect/*',
    '/public/advisor-directory-disqualified-advisors',
    '/public/advisor-directory-disqualified-advisors/*',
    '/public/advisor-request',
    '/public/advisor-request/*',
    '/purchase-completed',
];

const RoutesTrustWillowIframes = () => (
    <Switch>
        <PublicRoute exact path={'/public/schedule/:handle'} component={IFrameSchedule} />
        <PublicRoute exact path={'/public/schedule/:handle/:eventId'} component={IFrameSchedule} />
        <PublicRoute exact path={'/public/schedule/:handle/:eventId/*'} component={IFrameSchedule} />
        <PublicRoute exact path={'/public/reschedule/:token'} component={IFrameReschedule} />
        <PublicRoute exact path={'/public/products/:handle'} component={IFrameListings} />
        <PublicRoute exact path={'/public/products/:handle/:listingId'} component={IFrameListings} />
        <PublicRoute exact path={'/public/products/:handle/:listingId/*'} component={IFrameListings} />
        <PublicRoute exact path={'/public/webinars/:handle'} component={IFrameWebinars} />
        <PublicRoute exact path={'/public/webinars/:handle/:webinarId'} component={IFrameWebinars} />
        <PublicRoute exact path={'/public/webinars/:handle/:webinarId/*'} component={IFrameWebinars} />
        <PublicRoute exact path={'/public/webinar-training/free/:handle'} component={IFrameWebinarTrainingVideo} />
        <PublicRoute exact path={'/public/profile/:handle'} component={IFrameProfile} />
        <PublicRoute exact path={'/public/profile/:handle/*'} component={IFrameProfile} />
        <PublicRoute exact path={'/public/willow-profile/:handle'} component={IFrameWillowProfile} />
        <PublicRoute exact path={'/public/willow-profile/:handle/*'} component={IFrameWillowProfile} />
        <PublicRoute exact path={'/public/advisor-profile/:handle/*'} component={IFrameAdvisorProfile} />
        <PublicRoute exact path={'/public/advisor-profile/:handle'} component={IFrameAdvisorProfile} />
        <PublicRoute exact path={'/public/advisor-profile-v2/:handle/*'} component={IFrameAdvisorProfileV2} />
        <PublicRoute exact path={'/public/advisor-profile-v2/:handle'} component={IFrameAdvisorProfileV2} />
        <PublicRoute exact path={'/public/advisor-match-scheduled-call/*'} component={IFrameAdvisorProfileV2Scheduled} />
        <PublicRoute exact path={'/public/advisor-match-scheduled-call'} component={IFrameAdvisorProfileV2Scheduled} />
        <PublicRoute exact path={'/public/advisor-directory'} component={IFrameAdvisorDirectory} />
        <PublicRoute exact path={'/public/advisor-directory/*'} component={IFrameAdvisorDirectory} />
        <PublicRoute exact path={'/public/advisor-directory-redirect'} component={IFrameAdvisorDirectoryRedirect} />
        <PublicRoute exact path={'/public/advisor-directory-redirect/*'} component={IFrameAdvisorDirectoryRedirect} />
        <PublicRoute exact path={'/public/advisor-directory-disqualified-advisors'} component={IFrameBadActorAdvisorDirectory} />
        <PublicRoute exact path={'/public/advisor-directory-disqualified-advisors/*'} component={IFrameBadActorAdvisorDirectory} />
        <PublicRoute exact path={'/public/advisor-request'} component={IFrameAdvisorRequest} />
        <PublicRoute exact path={'/public/advisor-request/*'} component={IFrameAdvisorRequest} />
        <PublicRoute exact path={'/public/shop/:handle'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/products'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/products/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/testimonials'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/testimonials/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/faqs'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/faqs/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/portfolio'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/portfolio/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/about'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/about/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/webinars'} component={IFrameShop} />
        <PublicRoute exact path={'/public/shop/:handle/webinars/*'} component={IFrameShop} />
        <PublicRoute exact path={'/public/checkout/:planId'} component={IFrameCheckout} />
        <PublicRoute exact path={'/public/programs'} component={IFramePrograms} />
        <PublicRoute exact path={'/public/programs/:programId'} component={IFramePrograms} />
        <PublicRoute exact path={'/public/programs/:programId/*'} component={IFramePrograms} />
        <PublicRoute exact path={'/message-room/:roomId'} component={IFrameSMS} />
        <PublicRoute exact path={'/rating/:roomId'} component={IframeRatings} />
        <PublicRoute exact path={'/client-rating/:roomId'} component={IframeRatings} />
        {/*<PublicRoute exact path={'/package/:roomId'} component={IframeCheckoutWillowPackages} />*/}
        {/*<PublicRoute exact path={'/thank-you/:roomId'} component={IframeCheckoutWillowReceipt} />*/}
        <PublicRoute exact path={'/error'} component={IframeError} />
        <PublicRoute exact path={'/private/expert/create-user'} component={IframeExpertCreateUser} />
        <PublicRoute exact path={'/private/wmc/create-user'} component={IframeWMCCreateUser} />
        <PublicRoute exact path={'/private/coach-matching'} component={IframeMatching} />
        <PublicRoute exact path={'/password-reset'} component={IFrameResetPassword} />
        <PublicRoute exact path={'/employee-register'} component={IFrameEmployerLogin} />
        <PublicRoute exact path={'/login'} component={IFrameLogin} />
        <PublicRoute exact path={'/signup'} component={IFrameSignup} />
        <PublicRoute exact path={'/get-started'} component={IFrameSignupMatch} />
        <PublicRoute exact path={'/get-matched'} component={IFrameSignupMatch} />
        <PublicRoute exact path={'/get-scheduled'} component={IFrameSignupMatch} />
        <PublicRoute exact path={'/advisor-signup-no-cert'} component={IFrameAdvisorSignup} />
        // <PublicRoute exact path={'/advisor-signup'} component={IFrameAdvisorSignupPay} />
        <PublicRoute exact path={'/advisor-verify'} component={IFrameSignupAdvisorSuccess} />
        <PublicRoute exact path={'/purchase-completed'} component={IFrameCheckoutStripe} />
    </Switch>
);

export default RoutesTrustWillowIframes;
